<template>
  <div class="main">
    <Title
      v-on:close-dialog="closeDialog()"
      :text="`DOCUMENTOS ${getPath}`"
      :clossable="clossable"
    />
    <p
      class="back" @click="back()"
      style="position: absolute">
      <i class="el-icon-arrow-left"/>
      Atras
    </p>
    <div class="folders" v-if="getDirectory" style="max-height: 580px">
      <div
        class="folder"
        v-for="(folder, index) in getDirectory"
        :key="index"
        @click="selectFolder(folder)">
        <div class="img-container">
          <img src="@/assets/folder.png">
          <p>{{folder}}</p>
        </div>
      </div>
    </div>
    <div class="main" v-else style="margin-top:50px">
      <div class="files">
        <div class="header title">
          <p class="name">NOMBRE</p>
          <p class="date">INGRESO</p>
          <p class="createdBy">PUBLICADO POR</p>
        </div>
        <div
          class="file"
          v-for="(file, index) in archivos"
          :key="index"
        >
          <div class="prev-container">
            <el-image
              v-if="file.mimetype.split('/')[0] === 'image'"
              class="preview"
              :src="`${baseURL}/static/file/${file.patientId}/${file.id}.${file.ext}`"
              :preview-src-list="
                [`${baseURL}/static/file/${file.patientId}/${file.id}.${file.ext}`]
              "
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"/>
              </div>
            </el-image>
            <div
              style="height: 100%;width:100%"
              v-if="file.mimetype.split('/')[1] === 'pdf'"
            >
              <img src="../../assets/pdf-badge.png" alt="pdf icon">
            </div>
            <div
              style="height: 100%;width:100%"
              v-if="
                file.mimetype.split('/')[1] !== 'pdf'
                && file.mimetype.split('/')[0] !== 'image'"
            >
              <img src="../../assets/file-badge.png" alt="file icon">
            </div>
          </div>
          <div style="width: 40%">
            <p>{{`${file.name}.${file.ext}`}}</p>
          </div>
          <div class="content" style="width: 20%">
            <p>{{file.createdAt}}</p>
          </div>
          <div class="content" style="width: 20%">
            <p>{{file.creator.firstName}} {{file.creator.lastName}}</p>
          </div>
          <a
            :href="`${baseURL}/static/file/${file.patientId}/${file.id}.${file.ext}`"
            target="_blank"
            :download="`${file.id}.${file.ext}`"
            class="content"
            style="
              width: 20%;
              display:flex;
              justify-content:center;
              align-items:center;
          ">
            <i class="el-icon-download" style="font-size:20px"/>
          </a>
          <div class="actions">
            <el-button
              round
              size="small"
              type="danger"
              style="padding:5px"
              @click="deleteFile(index, file)"
            >
            <i class="el-icon-close"/>
          </el-button>
          </div>
        </div>
      </div>
      <el-button type="primary" @click="handleOpenFile">
        <i class="el-icon-upload"/>
        Adjuntar archivo
      </el-button>
      <input
        multiple
        type="file"
        id="docsInp"
        ref="files"
        style="display: none;"
        v-on:change="addFile"
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import Moment from 'moment';
import { originalURL } from '../../API';
import Title from '../../components/Title';

export default {
  name: 'Docs',
  props: {
    patientId: {
      type: Number,
    },
    selectedFolder: {
      type: String,
      default: null,
    },
    showReturn: {
      type: Boolean,
      default: true,
    },
    clossable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      baseURL: originalURL,
      srcList: [],
      folders: [
        { name: 'ANTECEDENTES' },
        { name: 'FICHA TÉCNICA' },
        {
          name: 'OPTOMETRÍA',
          children: [
            {
              name: 'ANGIOGRAFÍAS',
            },
            {
              name: 'CÁLCULO DE LENTES',
            },
            {
              name: 'TOPOGRAFÍAS',
            },
            {
              name: 'CAMPO VISUAL',
            },
            { name: 'PAQUIMETRÍAS' },
            {
              name: 'OCT',
              children: [
                { name: 'OCT MACULAR' },
                { name: 'OCT NERVIO ÓPTICO' },
                { name: 'OCT CÓRNEA' },
              ],
            },
            {
              name: 'MICROESCOPÍA',
            },
            { name: 'OTROS' },
          ],
        },
        { name: 'OFTALMOLOGÍA' },
        { name: 'TRABAJO SOCIAL' },
      ],
      data: [],
      breadcrumb: '',
      history: [],
      patient: null,
      files: [],
      uploadFiles: [],
    };
  },
  components: {
    Title,
  },
  computed: {
    ...mapState({
      roleID: (state) => state.auth.user.role.id,
    }),
    currentFolder() {
      return this.history[this.history.length - 1];
    },
    getPath() {
      const subfolders = this.history.filter((f) => f.name);
      return subfolders.reduce((prev, curr) => `${prev}/${curr.name}`, '');
    },
    archivos() {
      return this.data.filter((x) => x.virtualPath === this.getPath);
    },
    getDirectory() {
      const base = Array.isArray(this.currentFolder)
        ? this.currentFolder : this.currentFolder?.children;
      if (!base) {
        return null;
      }
      return base.map((folder) => folder.name);
    },
  },
  methods: {
    back() {
      if (this.history.length > 1) {
        this.history.pop();
      }
    },
    selectFolder(folder) {
      const base = Array.isArray(this.currentFolder)
        ? this.currentFolder : this.currentFolder?.children ?? this.folders;
      const selectedFolder = base.find((f) => f.name === folder);
      this.history.push(selectedFolder);
      this.$emit('on-change-folder', folder);
    },
    handleOpenFile() {
      document.getElementById('docsInp').click();
    },
    async addFile() {
      try {
        const { id } = this.$route.params;
        const val = this.patientId || id;
        const { files } = this.$refs.files;
        this.uploadFiles = [...this.files, ...files];
        this.files = [
          ...this.files,
          ...this.getPath,
          ..._.map(files, (file) => ({
            name: file.name,
            size: file.size,
            type: file.type,
          })),
        ];
        const formData = new FormData();
        _.forEach(this.uploadFiles, (file) => {
          formData.append('files', file);
        });
        formData.append('virtualPath', this.getPath);
        const response = await this.axios({
          url: `patient/file/${val}/multiple`,
          method: 'POST',
          data: formData,
          headers: {
            'content-type': 'application/form-data',
          },
        });
        if (response) {
          await this.axios.post(`patient/changelog/${val}`, {
            patient: val,
            title: this.currentFolder.name.toUpperCase(),
            message: 'Se agregó archivo',
          });
          this.files = [];
          this.uploadFiles = [];
          await this.getData();
        }
        // if (file.length) {
        //   const MbLimit = 10;
        //   const size = file[0].size / 1024 / 1024 < MbLimit;
        //   if (size) {
        //     const { id } = this.$route.params;
        //     const val = id || this.patientId;
        //     const data = new FormData();
        //     data.append('file', file);
        //     data.append('virtualPath', this.getPath);
        //     const response = await this.axios({
        //       url: `patient/file/${val}`,
        //       method: 'POST',
        //       data,
        //       headers: {
        //         'content-type': 'application/form-data',
        //       },
        //     });
        //     if (response) {
        //       await this.axios.post(`patient/changelog/${val}`, {
        //         patient: val,
        //         title: this.currentFolder.name.toUpperCase(),
        //         message: 'Se agregó archivo',
        //       });
        //       await this.getData();
        //     }
        //   } else {
        //     this.$message({
        //       type: 'error',
        //       message: 'La imagen excede el tamaño limite,',
        //       showClose: false,
        //     });
        //   }
        // } else {
        //   this.$message({
        //     type: 'error',
        //     message: 'Archivo no encontrado',
        //     showClose: false,
        //   });
        // }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
    async getData() {
      try {
        const { id } = this.$route.params;
        const val = this.patientId || id;
        const response = await this.axios.get(`patient/file/${val}`);
        const { data } = response;
        const sortedArray = data.sort((a, b) => Moment(b.createdAt, 'YYYY-MM-DD').unix() - Moment(a.createdAt, 'YYYY-MM-DD').unix());
        this.data = sortedArray.map((item) => ({
          ...item,
          createdAt: Moment(item.createdAt).format('YYYY-MM-DD'),
        }));
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    async deleteFile(idx, item) {
      try {
        const response = await this.axios.delete(`patient/file/${item.id}`);
        const { data } = response;
        if (data) {
          const message = `Se ha eliminado el archivo ${item.name}.${item.ext}`;
          await this.axios.post(`patient/changelog/${this.patientId}`, {
            title: 'COMENTARIOS',
            message,
          });
          this.$notify({
            type: 'success',
            message,
          });
          this.data = this.data.filter((x) => x.id !== item.id);
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    hasPermission(section) {
      const id = this.roleID;
      if (id === 'ADMIN') {
        return true;
      }
      if (id === section) {
        return true;
      }
      return false;
    },
    switchFolder() {
      if (this.selectedFolder) {
        const paths = this.selectedFolder.split('/');
        this.history = [];
        paths.forEach((path) => {
          this.selectFolder(path);
        });
      }
    },
  },
  async mounted() {
    this.history.push(this.folders);
    this.switchFolder();
    await this.getData();
  },
  watch: {
    selectedFolder() {
      this.switchFolder();
    },
    async patientId() {
      this.history.push(this.folders);
      await this.getData();
    },
  },
};
</script>
<style lang="scss">
.el-image-viewer__mask {
  opacity: .97!important;
}
</style>
<style lang="scss" scoped>
.main {
  max-height: 500px;
  p {
    padding: 0px 10px;
  }
  .title {
    width: 100%;
    height: 60px;
    position: sticky;
    top: 0;
  }
  .back {
    font-size: 14px;
    color: #9B9B9B;
    width: 20%;
  }
  .back:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .el-button {
    float: right;
    margin-right: 15px;
    margin-top: 15px;
  }
  .actions {
    z-index: 10;
    position: absolute;
    right: -5px;
    top: -5px;
  }
  .folders {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .folder {
      width: 200px;
      height: 200px;
      padding: 30px;
      .img-container {
        width: 100%;
        height: 100%;
        img {
          object-fit: contain;
          width: 80%;
          margin-left: 10%;
          height: 80%;
          padding-bottom: 0px;
        }
        p {
          font-size: 15px;
          font-weight: bold;
          min-width: 145px;
          word-break: break-word;
        }
      }
    }
  }
  .files {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      background-color: #FFFFFF;
      z-index: 3;
      p {
        font-size: 12px;
        font-weight: bold;
        color: #000;
      }
      .name {
        width: 35%;
        margin-left: 10%;
      }
      .date {
        text-align: center;
        width: 18.5%;
      }
      .createdBy {
        text-align: center;
        width: 18.5%;
      }
    }
    .file {
      position: relative;
      display: flex;
      flex-direction: row;
      p {
        font-size: 12px;
        color: #000;
      }
      .content {
        text-align: center;
      }
      .prev-container {
        width: 10%;
        height: 40px;
        .preview {
          padding-left: 10%;
          width: 80%;
          height: 80%;
        }
        img {
          padding-left: 10%;
          width: 80%;
          height: 80%;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
